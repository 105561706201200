import { formatDate } from 'libs/dateTimeHelpers';
import getPermissionFromPages from 'libs/getPermissionFromPages';
import { accountPages, administrationPages } from 'pages/Administration/Main/pages';
import accountAuthRoutes from 'pages/Auth/AuthorizedSignUp/routes/account';
import companyAuthRoutes from 'pages/Auth/AuthorizedSignUp/routes/company';
import {
  administrationPages as companyAdministrationPages,
  companyPages,
} from 'pages/Company/Administration/pages';

export const WEBSITE_URL = 'condogenie.com';
export const WEBSITE_FULL_URL = `https://${WEBSITE_URL}`;
export const WEBSITE_PRIVACY_POLICY = `${WEBSITE_FULL_URL}/privacy-policy`;
export const WEBSITE_TERMS_CONDITIONS = `${WEBSITE_FULL_URL}/terms-conditions`;
export const VERSION_URL = 'https://condogenie.com/release-notes/';
export const HELP_URL = 'https://condogenie.zendesk.com/hc';
export const SUPPORT_EMAIL = 'support@condogenie.com';
export const SUPPORT_PHONE = '1-800-274-9704';
export const PROJECT_TITLE = 'CondoGenie';
export const PROJECT_YEAR = formatDate(new Date(), 'yyyy');

export const MAX_FILE_SIZE = 50;

export const WINDOW_WIDTH_SIZES = {
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
};

export const HTTP_STATUSES = {
  accepted: 202,
  badGateway: 502,
  badRequest: 400,
  conflict: 409,
  continue: 100,
  created: 201,
  expectationFailed: 417,
  failedDependency: 424,
  forbidden: 403,
  found: 302,
  gatewayTimeout: 504,
  gone: 410,
  httpVersionNotSupported: 505,
  imUsed: 226,
  insufficientStorage: 507,
  internalServerError: 500,
  lengthRequired: 411,
  locked: 423,
  methodNotAllowed: 405,
  movedPermanently: 301,
  multiStatus: 207,
  multipleChoices: 300,
  noContent: 204,
  nonAuthoritativeInformation: 203,
  notAcceptable: 406,
  notExtended: 510,
  notFound: 404,
  notImplemented: 501,
  notModified: 304,
  ok: 200,
  partialContent: 206,
  paymentRequired: 402,
  preconditionFailed: 412,
  processing: 102,
  proxyAuthenticationRequired: 407,
  requestEntityTooLarge: 413,
  requestTimeout: 408,
  requestUriTooLong: 414,
  requestedRangeNotSatisfiable: 416,
  resetContent: 205,
  seeOther: 303,
  serviceUnavailable: 503,
  switchingProtocols: 101,
  temporaryRedirect: 307,
  unauthorized: 401,
  unprocessableEntity: 422,
  unsupportedMediaType: 415,
  upgradeRequired: 426,
  useProxy: 305,
};

export const USER_ROLES = [
  { label: 'Board or Council Member', value: 'Board or Council Member' },
  { label: 'Owner', value: 'Owner' },
  { label: 'Renter', value: 'Renter' },
  { label: 'Property Manager', value: 'Property Manager' },
  { label: 'Other', value: 'Other' },
];

export const RESET_PASSWORD_KEYS = ['access-token', 'client', 'uid', 'expiry'];
export const RESET_PASSWORD_REDIRECT_PATH = 'reset';

export const TOKEN_KEYS = ['access-token', 'token-type', 'client', 'uid', 'expiry'];

export const AMENITY_BOOKING_STATUSES = {
  approved: 'approved',
  pending: 'pending',
};

export const ADMIN_PERMISSIONS = [
  'accounts',
  'global_settings',
  'buildings',
  'units',
  'user_groups',
  'plan_and_billing',
  'invoices',
  'notices',
];

export const timezones = [
  // Etc/GMT+11 works vice versa!  "Etc/GMT-11|+11|-b0|0|" - in original file
  { value: 'Etc/GMT+12', label: '(GMT-12:00) International Date Line West' },
  { value: 'Pacific/Midway', label: '(GMT-11:00) Midway Island' },
  { value: 'Pacific/Samoa', label: '(GMT-11:00) Samoa' },
  { value: 'Pacific/Honolulu', label: '(GMT-10:00) Hawaii' },
  { value: 'America/Anchorage', label: '(GMT-09:00) Alaska' },
  { value: 'PST8PDT', label: '(GMT-08:00) Pacific Time (US & Canada)' },
  { value: 'America/Tijuana', label: '(GMT-08:00) Tijuana' },
  { value: 'America/Phoenix', label: '(GMT-07:00) Arizona' },
  { value: 'America/Chihuahua', label: '(GMT-07:00) Chihuahua' },
  { value: 'America/Mazatlan', label: '(GMT-07:00) Mazatlan' },
  {
    label: '(GMT-07:00) Mountain Time (US & Canada)',
    value: 'MST7MDT',
  },
  { value: 'America/Chicago', label: '(GMT-06:00) Central America' },
  { value: 'CST6CDT', label: '(GMT-06:00) Central Time (US & Canada)' },
  { value: 'America/Mexico_City', label: '(GMT-06:00) Mexico City' },
  { value: 'America/Monterrey', label: '(GMT-06:00) Monterrey' },
  { value: 'America/Regina', label: '(GMT-06:00) Saskatchewan' },
  { value: 'America/Bogota', label: '(GMT-05:00) Bogota' },
  { value: 'EST5EDT', label: '(GMT-05:00) Eastern Time (US & Canada)' },
  { value: 'America/Fort_Wayne', label: '(GMT-05:00) Indiana (East)' },
  { value: 'America/Lima', label: '(GMT-05:00) Lima' },
  { value: 'America/Caracas', label: '(GMT-04:30) Caracas' },
  { value: 'America/Halifax', label: '(GMT-04:00) Atlantic Time (Canada)' },
  { value: 'America/La_Paz', label: '(GMT-04:00) La Paz' },
  { value: 'America/Santiago', label: '(GMT-04:00) Santiago' },
  { value: 'America/St_Johns', label: '(GMT-03:30) Newfoundland' },
  { value: 'America/Sao_Paulo', label: '(GMT-03:00) Brasilia' },
  { value: 'America/Argentina/Buenos_Aires', label: '(GMT-03:00) Buenos Aires' },
  { value: 'America/Godthab', label: '(GMT-03:00) Greenland' },
  { value: 'Atlantic/South_Georgia', label: '(GMT-02:00) Mid-Atlantic' },
  { value: 'Atlantic/Azores', label: '(GMT-01:00) Azores' },
  { value: 'Atlantic/Cape_Verde', label: '(GMT-01:00) Cape Verde Is.' },
  { value: 'Africa/Casablanca', label: '(GMT+00:00) Casablanca' },
  { value: 'Europe/Dublin', label: '(GMT+00:00) Dublin' },
  { value: 'Europe/Lisbon', label: '(GMT+00:00) Lisbon' },
  { value: 'Europe/London', label: '(GMT+00:00) London' },
  { value: 'Etc/UTC', label: '(GMT+00:00) UTC' },
  { value: 'Europe/Amsterdam', label: '(GMT+01:00) Amsterdam' },
  { value: 'Europe/Belgrade', label: '(GMT+01:00) Belgrade' },
  { value: 'Europe/Berlin', label: '(GMT+01:00) Berlin' },
  { value: 'Europe/Brussels', label: '(GMT+01:00) Brussels' },
  { value: 'Europe/Budapest', label: '(GMT+01:00) Budapest' },
  { value: 'Europe/Copenhagen', label: '(GMT+01:00) Copenhagen' },
  { value: 'Europe/Madrid', label: '(GMT+01:00) Madrid' },
  { value: 'Europe/Paris', label: '(GMT+01:00) Paris' },
  { value: 'Europe/Prague', label: '(GMT+01:00) Prague' },
  { value: 'Europe/Rome', label: '(GMT+01:00) Rome' },
  { value: 'Europe/Stockholm', label: '(GMT+01:00) Stockholm' },
  { value: 'Europe/Vienna', label: '(GMT+01:00) Vienna' },
  { value: 'Europe/Warsaw', label: '(GMT+01:00) Warsaw' },
  { value: 'Africa/Lagos', label: '(GMT+01:00) West Central Africa' },
  { value: 'Europe/Athens', label: '(GMT+02:00) Athens' },
  { value: 'Europe/Bucharest', label: '(GMT+02:00) Bucharest' },
  { value: 'Africa/Cairo', label: '(GMT+02:00) Cairo' },
  { value: 'Africa/Maputo', label: '(GMT+02:00) Harare' },
  { value: 'Europe/Helsinki', label: '(GMT+02:00) Helsinki' },
  { value: 'Europe/Istanbul', label: '(GMT+02:00) Istanbul' },
  { value: 'Asia/Jerusalem', label: '(GMT+02:00) Jerusalem' },
  { value: 'Africa/Johannesburg', label: '(GMT+02:00) Pretoria' },
  { value: 'Europe/Riga', label: '(GMT+02:00) Riga' },
  { value: 'Europe/Sofia', label: '(GMT+02:00) Sofia' },
  { value: 'Europe/Tallinn', label: '(GMT+02:00) Tallinn' },
  { value: 'Europe/Vilnius', label: '(GMT+02:00) Vilnius' },
  { value: 'Asia/Baghdad', label: '(GMT+03:00) Baghdad' },
  { value: 'Asia/Riyadh', label: '(GMT+03:00) Kuwait' },
  { value: 'Europe/Kiev', label: '(GMT+03:00) Kyiv' },
  { value: 'Europe/Minsk', label: '(GMT+03:00) Minsk' },
  { value: 'Africa/Nairobi', label: '(GMT+03:00) Nairobi' },
  { value: 'Asia/Riyadh', label: '(GMT+03:00) Riyadh' },
  { value: 'Asia/Tehran', label: '(GMT+03:30) Tehran' },
  { value: 'Asia/Baku', label: '(GMT+04:00) Baku' },
  { value: 'Europe/Moscow', label: '(GMT+04:00) Moscow' },
  { value: 'Asia/Dubai', label: '(GMT+04:00) Muscat' },
  { value: 'Asia/Tbilisi', label: '(GMT+04:00) Tbilisi' },
  { value: 'Europe/Volgograd', label: '(GMT+04:00) Volgograd' },
  { value: 'Asia/Yerevan', label: '(GMT+04:00) Yerevan' },
  { value: 'Asia/Kabul', label: '(GMT+04:30) Kabul' },
  { value: 'Asia/Karachi', label: '(GMT+05:00) Karachi' },
  { value: 'Asia/Tashkent', label: '(GMT+05:00) Tashkent' },
  { value: 'Asia/Kolkata', label: '(GMT+05:30) Kolkata' },
  { value: 'Asia/Colombo', label: '(GMT+05:30) Sri Jayawardenepura' },
  { value: 'Asia/Kathmandu', label: '(GMT+05:45) Kathmandu' },
  { value: 'Asia/Almaty', label: '(GMT+06:00) Almaty' },
  { value: 'Asia/Dhaka', label: '(GMT+06:00) Dhaka' },
  { value: 'Asia/Yekaterinburg', label: '(GMT+06:00) Ekaterinburg' },
  { value: 'Asia/Rangoon', label: '(GMT+06:30) Rangoon' },
  { value: 'Asia/Bangkok', label: '(GMT+07:00) Bangkok' },
  { value: 'Asia/Jakarta', label: '(GMT+07:00) Jakarta' },
  { value: 'Asia/Novosibirsk', label: '(GMT+07:00) Novosibirsk' },
  { value: 'Asia/Taipei', label: '(GMT+08:00) Beijing' },
  { value: 'Asia/Shanghai', label: '(GMT+08:00) Chongqing' },
  { value: 'Asia/Hong_Kong', label: '(GMT+08:00) Hong Kong' },
  { value: 'Asia/Krasnoyarsk', label: '(GMT+08:00) Krasnoyarsk' },
  { value: 'Asia/Kuala_Lumpur', label: '(GMT+08:00) Kuala Lumpur' },
  { value: 'Australia/Perth', label: '(GMT+08:00) Perth' },
  { value: 'Asia/Singapore', label: '(GMT+08:00) Singapore' },
  { value: 'Asia/Taipei', label: '(GMT+08:00) Taipei' },
  { value: 'Asia/Ulaanbaatar', label: '(GMT+08:00) Ulaan Bataar' },
  { value: 'Asia/Urumqi', label: '(GMT+08:00) Urumqi' },
  { value: 'Asia/Irkutsk', label: '(GMT+09:00) Irkutsk' },
  { value: 'Asia/Seoul', label: '(GMT+09:00) Seoul' },
  { value: 'Asia/Tokyo', label: '(GMT+09:00) Tokyo' },
  { value: 'Australia/Adelaide', label: '(GMT+09:30) Adelaide' },
  { value: 'Australia/Darwin', label: '(GMT+09:30) Darwin' },
  { value: 'Australia/Brisbane', label: '(GMT+10:00) Brisbane' },
  { value: 'Australia/Sydney', label: '(GMT+10:00) Canberra' },
  { value: 'Pacific/Guam', label: '(GMT+10:00) Guam' },
  { value: 'Australia/Hobart', label: '(GMT+10:00) Hobart' },
  { value: 'Australia/Melbourne', label: '(GMT+10:00) Melbourne' },
  { value: 'Pacific/Port_Moresby', label: '(GMT+10:00) Port Moresby' },
  { value: 'Australia/Sydney', label: '(GMT+10:00) Sydney' },
  { value: 'Asia/Yakutsk', label: '(GMT+10:00) Yakutsk' },
  { value: 'Pacific/Noumea', label: '(GMT+11:00) New Caledonia' },
  { value: 'Asia/Vladivostok', label: '(GMT+11:00) Vladivostok' },
  { value: 'Pacific/Auckland', label: '(GMT+12:00) Auckland' },
  { value: 'Pacific/Fiji', label: '(GMT+12:00) Fiji' },
  { value: 'Asia/Kamchatka', label: '(GMT+12:00) Kamchatka' },
  { value: 'Asia/Magadan', label: '(GMT+12:00) Magadan' },
  { value: 'Pacific/Majuro', label: '(GMT+12:00) Marshall Is.' },
  { value: 'Pacific/Guadalcanal', label: '(GMT+12:00) Solomon Is.' },
  { value: 'Pacific/Tongatapu', label: '(GMT+13:00) Tongatapu' },
];

export const UTC_TIMEZONE = 'Etc/UTC';
export const DEFAULT_TIMEZONE = UTC_TIMEZONE;

export const PROJECT_STATUSES = [
  { label: 'Opened', value: 'opened' },
  { label: 'Completed', value: 'completed' },
];

export const DEFAULT_PLAN = 'collaborate';

export const MAX_SMS_LENGTH = 140;
export const MAX_PHONE_MESSAGE_LENGTH = 155;
export const MAX_SIDEBAR_NOTICE_LENGTH = 140;

export const TASK_DAYS_ERROR = 0;
export const TASK_DAYS_WARNING = 3;

export const EDITOR_DEFAULT_COLORS = [
  'rgb(  0,   0,   0)',
  'rgb(230,   0,   0)',
  'rgb(255, 153,   0)',
  'rgb(255, 255,   0)',
  'rgb(  0, 138,   0)',
  'rgb(  0, 102, 204)',
  'rgb(153,  51, 255)',
  'rgb(255, 255, 255)',
  'rgb(250, 204, 204)',
  'rgb(255, 235, 204)',
  'rgb(255, 255, 204)',
  'rgb(204, 232, 204)',
  'rgb(204, 224, 245)',
  'rgb(235, 214, 255)',
  'rgb(187, 187, 187)',
  'rgb(240, 102, 102)',
  'rgb(255, 194, 102)',
  'rgb(255, 255, 102)',
  'rgb(102, 185, 102)',
  'rgb(102, 163, 224)',
  'rgb(194, 133, 255)',
  'rgb(136, 136, 136)',
  'rgb(161,   0,   0)',
  'rgb(178, 107,   0)',
  'rgb(178, 178,   0)',
  'rgb(  0,  97,   0)',
  'rgb(  0,  71, 178)',
  'rgb(107,  36, 178)',
  'rgb( 68,  68,  68)',
  'rgb( 92,   0,   0)',
  'rgb(102,  61,   0)',
  'rgb(102, 102,   0)',
  'rgb(  0,  55,   0)',
  'rgb(  0,  41, 102)',
  'rgb( 61,  20,  10)',
];

export const EMAIL_SEND_DELAY_SECONDS = 60;

export const WEBSITE_ROUTES = ['/'];

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png'];

export const TASK_STATUSES = [
  { label: 'All', value: ' ' },
  { label: 'Open', value: 0 },
  { label: 'In progress', value: 2 },
  { label: 'Completed', value: 1 },
];

export const RECURRINGS = ['daily', 'weekly', 'monthly', 'yearly'];

export const SELECT_ALL_OPTION = { label: 'All', value: 0 };
export const ALL_SELECTED_BACKEND_VALUE = 'all';

export const STRONG_ACCOUNT_ADMIN_PAGES_PERMISSIONS = {
  ...getPermissionFromPages(administrationPages),
  ...getPermissionFromPages(accountPages),
};

export const STRONG_COMPANY_ADMIN_PAGES_PERMISSIONS = {
  ...getPermissionFromPages(companyAdministrationPages),
  ...getPermissionFromPages(companyPages),
};

export const ACCOUNT_DASHBOARD_PERMISSIONS = [
  'amenity_bookings',
  'calendar_events',
  'classifieds',
  'documents',
  'message_forum',
  'message_forum_comments',
  'news_announcements',
  'requests',
  'tasks',
  'unit_messages',
  'unit_messages_comments',
  'users_accounts',
];

export const ACCOUNT_GLOBAL_SETTINGS_PERMISSIONS = ['features', 'document_categories'];

export const CALENDAR_EVENTS_LIMIT = 2;

export const ADMINISTRATION_ICON_SIZE = 73;

export const AMENITY_FEE_PERIOD = [
  {
    label: '1 hour',
    value: 'hour',
  },
  {
    label: '1 day',
    value: 'day',
  },
  {
    label: '1 week',
    value: 'week',
  },
  {
    label: '1 month',
    value: 'month',
  },
  {
    label: '1 year',
    value: 'year',
  },
];

export const TUTORIALS_PATHS = {
  messageForum: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152743?autoplay=1&hd=1&show_title=1&show_byline=1&show_portrait=0&fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006432632-Message-Forum',
  },
  tasks: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152864?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006511971-Tasks',
  },
  news: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152776?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360006510891-News-Announcements',
  },
  documents: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152719?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006511931-Documents',
  },
  classifieds: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152678?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006432652-Classifieds',
  },
  requests: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152823?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009390991-Requests',
  },
  amenities: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430413416?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006432672-Amenities',
  },
  calendar: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431010325?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360006511971-Tasks',
  },
  unitMessages: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431010331?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009278532-Unit-Messages',
  },
  units: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431232889?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009391031-Units',
  },
  users: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431232900?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360006512011-User-Administration',
  },
  amenityBookings: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431022375?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360009391051-Amenity-Bookings',
  },
  website: {
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360006432692-Website-Maintenance',
  },
  notices: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431022396?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009391091-Notices',
  },
  globalSettings: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431022384?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360009278592-Global-Settings',
  },
  groups: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152643?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009391111-User-Groups',
  },
  projects: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/431022403?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009391071-Projects',
  },
  taskCategories: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152637?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl:
      'https://condogenie.zendesk.com/hc/en-us/sections/360009391131-Task-Categories',
  },
  buildings: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430182166?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
    docTutorialsUrl: 'https://condogenie.zendesk.com/hc/en-us/sections/360009391151-Buildings',
  },
  dashboard: {
    videoTutorialsUrl:
      '//player.vimeo.com/video/430152698?autoplay=1&amp;hd=1&amp;show_title=1&amp;show_byline=1&amp;show_portrait=0&amp;fullscreen=1',
  },
};

export const formattedSchedule: { [index: string]: string } = {
  daily: 'day',
  monthly: 'month',
  weekly: 'week',
  yearly: 'year',
};

export const PRIMARY_TIME_FORMAT = 'hh:mm a';
export const SECONDARY_TIME_FORMAT = PRIMARY_TIME_FORMAT;
export const H24_TIME_FORMAT = 'HH:mm';

export const COMMON_DATE_FORMAT = 'MMM dd, yyyy';
export const SECONDARY_DATE_FORMAT = "do 'of' MMMM, yyyy";
export const COMMON_DATE_TIME_FORMAT = 'MMM dd, yyyy hh:mm a';

export const RECURRING_EDIT_ALL_FLAG = 'all_future_items';

// 10 years
export const COOKIES_LIFETIME = 1000 * 60 * 60 * 24 * 365 * 10;

export const COMMON_TYPE_ACCOUNT = 'account';
export const COMMON_TYPE_COMPANY = 'company';

export const TOAST_LIFETIME = 3000;

export const THEME_NO_SITE = 'ThemeNoSite';

export const URL_TO_TASKS_LIST = '/tasks?status=0&archive=active';
export const URL_TO_REQUESTS_LIST = '/requests?status=0&archive=active';

export const AUTH_RENDER_ROUTES_URLS = [
  ...accountAuthRoutes.map((r) => r.path),
  ...companyAuthRoutes.map((r) => r.path),
];
