import ErrorView from 'components/ErrorView';
import useCommon from 'hooks/useCommon';
import { capitalize } from 'libs/utils';
import React, { FunctionComponent } from 'react';
import useNavigator from 'hooks/useNavigator';

const NotFound: FunctionComponent = () => {
  const { title } = useCommon();
  const { navigateCallback } = useNavigator();

  return (
    <ErrorView
      title="Ooops... Something went wrong."
      message="But don't worry, we're working on it!"
      actionTitle={capitalize(title)}
      onClick={navigateCallback('/')}
    />
  );
};

export default NotFound;
